import Vue from 'vue'

let rAFTicker = false
const callbacks = []
let callbackCounter = 0

// Request animation Frame
function windowScrollCallbacks () {
    let pageOffset = window.pageYOffset

    callbacks.forEach(function (option) {
        option.callback(pageOffset)
    })

    rAFTicker = false
}

function requestTicker () {
    if (!rAFTicker) {
        rAFTicker = true
        window.requestAnimationFrame(windowScrollCallbacks)
    }
}

let passiveOptions = false
function supportPassiveListener () {
    try {
    const options = {
        get passive () { // This function will be called when the browser
        //   attempts to access the passive property.
        passiveOptions = true
        return false
        }
    }

    window.addEventListener('test', null, options)
    window.removeEventListener('test', null, options)
    } catch (err) {
        passiveOptions = false
    }
}

function attachScrollEvent () {
    supportPassiveListener()

    // only listen for scroll events
    window.addEventListener('scroll', requestTicker, passiveOptions)
    window.addEventListener('resize', requestTicker, passiveOptions)
}

function removeScrollEvent (id) {
    if (callbacks.length === 1) {
        callbacks.pop()
        window.removeEventListener('scroll', requestTicker, passiveOptions)
        window.removeEventListener('resize', requestTicker, passiveOptions)
    } else {
        const indexToRemove = findIndexWithId(callbacks, id)

        if (indexToRemove >= 0) {
        callbacks.splice(indexToRemove, 1)
        }
    }
}

function addScrollCallback (option) {
    if (!callbacks.length) {
        attachScrollEvent()
    }

    if (typeof option.callback === 'function') {
        callbacks.push(option)
    } else {
        console.log(option.callback, 'was not a function')
    }
}

// End Request Animation Service
function findIndexWithId (cbArray, id) {
    const foundItem = cbArray.filter((item, index) => (item.id === id) ? index : false)

    return (foundItem.length === 1) ? foundItem[0] : -1
}

Vue.directive('scroll', {
    bind: (el, { name, value: callback, arg }) => {
        const self = Vue.directive(name)

        addScrollCallback({
            id: callbackCounter,
            callback: callback
        })

        // Slight delay to allow content and fonts to load
        window.setTimeout(() => {
            windowScrollCallbacks()
        }, 500)

        self.id = callbackCounter
        ++callbackCounter
    },
    unbind: (el, { name }) => {
        const self = Vue.directive(name)

        removeScrollEvent(self.id)
    },
    id: 0
})
