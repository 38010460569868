import Vue from 'vue'
import './components'
import './directives'

window.App = new Vue({
    el: '#vue-app',
    data: {
        $header: null,
        headerHeight: 0,
        isMobile: false,
        viewportDimensions: {
            width: 0,
            height: 0
        }
    },
    methods: {
        handleResizeEvent () {
            this.viewportDimensions.width = window.innerWidth
            this.viewportDimensions.height = window.innerHeight

            this.isMobile = window.innerWidth < 767

            this.calculateHeaderHeight()
        },
        calculateHeaderHeight () {
            this.headerHeight = this.$header.getBoundingClientRect().height
        },
        initHeader () {
            this.$header = document.querySelector('.js-site-header')
            this.calculateHeaderHeight()
        }
    },
    mounted () {
        this.initHeader()
        this.handleResizeEvent()
    }
})
