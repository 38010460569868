import Vue from 'vue'
import { gsap } from 'gsap-new'
import scrollTo from '../mixins/scrollTo'
import { CSSRulePlugin } from "gsap-new/CSSRulePlugin";


gsap.registerPlugin(CSSRulePlugin);

Vue.component('CareersHero', {
    mixins: [scrollTo],
    methods: {
        scrollToProfile (elementID) {
            const element = document.querySelector(`[title="${elementID}"]`)

            this.scrollTo(element, 1800)
        },
        scrollViewPort () {
            this.scrollTo(window.innerHeight, 1800)
        },
        initialScroll() {
            if (window.scrollY > 0) {
                // console.log(window,scrollY);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        },  
        heroAnimation() {
            function initEnter() {
                let tl = gsap.timeline();
                tl.to(".careers-hero__body", {"--shade-height": 0, ease: "power3.out", duration: .75});
                return tl;
            }

            function textEnter() {
                let tl = gsap.timeline();
                tl.to(".careers-hero__animation-text", {
                    "opacity": 1, y: 0, ease: "power3.out", duration: .75,
                    stagger: {
                        each: 0.2,
                        ease: "power3.out"
                    }
                });
                return tl;
            }

            function connections() {
                let tl = gsap.timeline();
                tl.to(".careers-hero__animation-connection", {
                    "opacity": 1, 
                    ease: "none",
                    stagger: {
                        each: 0.2
                    }});
                tl.to(".careers-hero__animation-label", {
                    "opacity": 1, y: 0, 
                    ease: "none",
                    stagger: {
                        each: 0.2
                    }},
                    "<.4");
                return tl;
            }

            var master = gsap.timeline({defaults: {ease: "none"}});

            master.add(initEnter());
            master.add(textEnter(), "-=.5");
            master.add(connections(), "-=.7");

        }
    },
    mounted() {
        this.heroAnimation();
        this.initialScroll();
    }
})
