import Vue from 'vue'

Vue.component('Questionnaire', {
    props: {
        questions: Array,
        sectionLabel: {
            type: String,
            default: ''
        }
    },
    inject: ['updateNavigationState'],
    provide () {
        return {
            answerQuestion: this.answerQuestion,
            completeQuestionStep: this.completeQuestionStep
        }
    },
    data () {
        return {
            currentQuestion: 0,
            currentQuestionSet: 0,
            finalAnswer: {},
            isStudent: undefined,
            results: [],
            notStarted: true
        }
    },
    computed: {
        showStartOver () {
            return this.currentQuestionSet >= this.questions.length
        }
    },
    methods: {
        reset () {
            this.currentQuestionSet = 0
            this.currentQuestion = 0.1
            this.finalAnswer = {}
            this.results = []
        },
        completeQuestionStep () {
            this.currentQuestion = parseFloat((this.currentQuestion + 0.1).toFixed(1))
        },
        answerQuestion(questionIndex, questionAnswer) {            
            this.results.push({ question: this.questions[questionIndex], answer: questionAnswer })

            if (questionIndex === 0) {
                this.isStudent = questionAnswer.ID == 'student' 
            }

            this.currentQuestionSet = questionIndex + 1
            this.currentQuestion = this.currentQuestionSet + 0.1

            //questionnaire complete
            if (this.showStartOver) {                
                this.finalAnswer = questionAnswer
            }
        },        
        backTrack(index) {
            this.currentQuestionSet = index
            this.currentQuestion = index + 0.1
            this.results.splice(index)
            this.finalAnswer = {}
        },
        handleScroll() {
            if (this.isInViewport()) {
                this.setCurrentQuestion()
            }
        },
        panelBounds() {
            return this.$el.getBoundingClientRect()
        },
        isInViewport() {
            if (this.panelBounds().top <= (this.$root.headerHeight + 50)) {
                this.updateNavigationState(this.sectionLabel)
                return true
            } else {
                return false
            }
        },
        setCurrentQuestion() {
            if (this.notStarted) {
                this.currentQuestion = 0.1
                this.notStarted = false
            }
        }
    }
})
