import Vue from 'vue'
import { gsap } from 'gsap-new'
import scrollTo from '../mixins/scrollTo'

const scrollAnimationTiming = 500

Vue.component('ProfilePanel', {
    props: {
        currentTab: {
            type: String,
            default: 'overview',
            required: true
        },
        sectionLabel: {
            type: String,
            default: ''
        }
    },
    mixins: [scrollTo],
    inject: ['updateNavigationState'],
    data () {
        return {
            activePanel: this.currentTab,
            panelPositionClass: '',
            videoElements: document.getElementsByTagName('video'),
            iframeElements: document.getElementsByTagName('iframe')
        }
    },
    computed: {
        showOverview () {
            return this.activePanel.includes('overview')
        }
    },  
    methods: {
        showConnection(panelName = 'overview') {
            //pause videos on connection change
            if (this.activePanel != panelName) {
              if (this.videoElements && this.videoElements.length > 0) {
                for (let i = 0; i < this.videoElements.length; i++) {
                  this.videoElements[i].pause()
                }
              }
              if (this.iframeElements && this.iframeElements.length > 0) {
                for (let i = 0; i < this.iframeElements.length; i++) {
                  this.iframeElements[i].src = this.iframeElements[i].src
                }
              }
            }

            this.scrollTo(this.$refs.profile, scrollAnimationTiming)
            this.activePanel = panelName
            window.setTimeout(() => {
                this.$refs[this.activePanel].focus()
            }, scrollAnimationTiming)
        },
        isActivePanel (panelName) {
            return this.activePanel === panelName
        },
        handleScroll () {
            if (this.isInViewport()) {
                if (this.profileBounds().height <= this.$root.viewportDimensions.height) {
                    this.panelPositionClass = ''
                    return
                } else if ((this.profileBounds().bottom - this.$root.viewportDimensions.height) <= 0) {
                    if (this.panelPositionClass !== 'profile--bottom-position') this.panelPositionClass = 'profile--bottom-position'
                } else {
                    if (this.panelPositionClass !== 'profile--sticky') this.panelPositionClass = 'profile--sticky'
                }
            } else {
                if (this.panelPositionClass !== '') this.panelPositionClass = ''
            }

            if (this.isWholePanelInViewport()) {
                this.updateNavigationState(this.sectionLabel)
            }
        },
        profileBounds () {
            return this.$refs.profile.getBoundingClientRect()
        },
        isInViewport () {
            return ((this.profileBounds().top <= this.$root.headerHeight) && (this.profileBounds().bottom >= this.$root.headerHeight)) 
        },
        panelBounds() {
            return this.$el.getBoundingClientRect()
        },
        isWholePanelInViewport() {
            return ((this.panelBounds().top <= (this.$root.headerHeight + 1)) && (this.panelBounds().bottom >= this.$root.headerHeight + (50))) // 50 is submenu height
        },
        beforeEnter(el) {
            gsap.set(el, { opacity: 0, x: this.showOverview ? -100 : 100 })
        },
        beforeLeave(el) {
            gsap.set(el, { position: 'absolute', top: 0 })
        },
        afterLeave: function (el) {
            gsap.set(el, { position: 'static' })
        },
        enter(el, done) {
            gsap.to(el, { duration: 1, x: 0, opacity: 1, onComplete: done})
        },
        leave(el, done) {
            gsap.to(el, { duration: 0.5, x: this.showOverview ? 100 : -100, opacity: 0, onComplete: done })
        }
    }
})