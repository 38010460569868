import Vue from 'vue'
import scrollTo from '../mixins/scrollTo'

Vue.component('LawyerCareersNavigation', {
    props: {
        activeNavigation: {
            type: String,
            default: ''
        }
    },
    mixins: [scrollTo],
    inject: ['updateNavigationState'],
    data () {
        return {
            showNavigation: false,
            expandMobileNavigation: false
        }
    },
    methods: {
        scrollToSection(section) {
            // const element = document.getElementById(section)
            const element = document.querySelector(`[title="${section}"]`)
            
            this.scrollTo(element, 1800)
            this.expandMobileNavigation = false
        },
        handleScroll (YPosition) {
            this.showNavigation = YPosition > (this.$root.viewportDimensions.height - this.$root.headerHeight - 50)
        },
        toggleMobileSideNavigation () {
            this.expandMobileNavigation = !this.expandMobileNavigation
        },
        isSectionInView(sectionLabel) {
            return this.activeNavigation === sectionLabel
        }
    }
})
