import Vue from 'vue'

Vue.component('QuestionnaireConclusion', {
    props: {
        currentQuestion: {
            type: Number,
            default: null,
            required: true
        },
        currentQuestionSet: {
            type: Number,
            default: null,
            required: true
        },
        questionSetIndex: {
            type: Number,
            default: null,
            required: true
        },
        stepIndex: {
            type: Number,
            default: null,
            required: true
        },
        title: {
            type: String,
            default: ''
        },
        link:{
            type: String,
            default: ''
        }
    },
    inject: ['answerQuestion', 'completeQuestionStep'],
    data() {
        return {
            animationCompleted: false,
            activeStartClass: 'inactive',
            counter: 0
        }
    },
    computed: {
        characters () {
            return typeof this.title === 'string'
                ? this.title.split('')
                : []
        },
        isOnThisStep () {
            return this.questionSetIndex + parseFloat(this.stepIndex / 10) === this.currentQuestion
        },
        completedClass () {
            return this.animationCompleted ? 'completed' : ''
        }
    },
    watch: {
        isOnThisStep (newVal) {
            if(newVal) {
                window.setTimeout(this.setActiveClasses, 100)
                window.setTimeout(this.attachEventListener, 100)
                window.setTimeout(this.setFocus, 100)
            } else {
                this.$nextTick(this.setActiveClasses)
                this.$nextTick(this.removeEventListener)
            }
        },
        currentQuestionSet (newVal) {
            if(newVal !== this.questionSetIndex) {
                this.animationCompleted = false
                this.activeStartClass = 'inactive'
            } else {
                console.log('old')
            }
        }
    },
    methods: {
        completeOnTransitionEnd (event) {
            event.stopPropagation()
            console.log('transitionEnd')
            this.animationCompleted = true
            this.completeQuestionStep()
        },
        attachEventListener() {
            
            const element = this.getLastLetterEl()
            
            if (element && !this.listenerAttached) {
                this.listenerAttached = true
                element.addEventListener('transitionend', this.completeOnTransitionEnd);
            }
        },
        removeEventListener() {
            const element = this.getLastLetterEl()
            if (element) {
                this.listenerAttached = false
                element.removeEventListener('transitionend', this.completeOnTransitionEnd);
            }
        },
        getLastLetterEl () {
            return this.characters.length
                ? this.$refs.letter[this.characters.length - 2]
                : null
        },
        setActiveClasses () {
            this.activeStartClass = (this.isOnThisStep) || this.animationCompleted
                ? 'active'
                : 'inactive'
        },
        setFocus() {
            // If the first Question in the group
            if (this.stepIndex === 1) {
                console.log(this.$refs.link, 'hello')
                this.$refs.link.focus()
            }
        },
    } 
})
