import Vue from 'vue'

Vue.component('QuestionnaireStep', {
    props: {
        currentQuestion: {
            type: Number,
            default: null,
            required: true
        },
        currentQuestionSet: {
            type: Number,
            default: null,
            required: true
        },
        questionSetIndex: {
            type: Number,
            default: null,
            required: true
        },
        stepIndex: {
            type: Number,
            default: null,
            required: true
        },
        Characters: {
            type: [Array, null],
            default: () => []
        },
        Options: {
            type: Object,
            default: () => ({})
        },
        Title:{
            type: String,
            default: ''
        }
    },
    inject: ['answerQuestion', 'completeQuestionStep'],
    data() {
        return {
            animationCompleted: false
        }
    },
    computed: {
        isOnThisStep () {
            return this.questionSetIndex + parseFloat(this.stepIndex / 10) === this.currentQuestion
        },
        activeStartClass () {
            return (this.isOnThisStep) || this.animationCompleted
                ? 'active'
                : 'inactive'
        },
        completedClass () {
            return this.animationCompleted ? 'completed' : ''
        }
    },
    watch: {
        isOnThisStep (newVal) {
            if(newVal) {
                this.attachEventListener()
                this.setFocus()
            } else {
                this.removeEventListener()
            }
        },
        currentQuestionSet (newVal) {
            if(newVal !== this.questionSetIndex) {  
                this.animationCompleted = false
            } 
        }
    },
    methods: {
        completeOnTransitionEnd (event) {
            event.stopPropagation()
            console.log('transitionEnd')
            this.animationCompleted = true
            this.completeQuestionStep()
        },
        attachEventListener() {
            const element = this.getLastLetterEl()
            
            if (element) {
                element.addEventListener('transitionend', this.completeOnTransitionEnd);
            }
        },
        removeEventListener() {
            const element = this.getLastLetterEl()
            if (element) {
                element.removeEventListener('transitionend', this.completeOnTransitionEnd);
            }
        },
        setFocus () {
            // If the first Question in the group
            if(this.stepIndex === 1) {
                if (this.$refs.heading[0]) this.$refs.heading[0].focus()
            }
        },
        getLastLetterEl () {
            if (Array.isArray(this.Characters)) {
                return this.$refs.letter[this.Characters.length - 1]
            }
        }
    } 
})
