import Vue from 'vue'

Vue.component('CareersMenu', {
    data () {
        return {
            showNavigation: false,
            isMenuOpen: false,
            mobileNav: $('.js-lawyer-careers-mobile-nav')
        }
    },
    computed: {

    },
    methods: {
        openMenu() {
            this.isMenuOpen = !this.isMenuOpen
            this.setAttributes(false)
        },
        setAttributes(doNotSetFocus) {
            if (!this.isMenuOpen) {
                this.mobileNav.find('a').attr('tabindex', -1)
            }
            else if (!doNotSetFocus) {                
                this.mobileNav.find('a').attr('tabindex', '').eq(0).focus()
            }
        },
        handleScroll (YPosition) {
            this.showNavigation = YPosition > (this.$root.viewportDimensions.height - this.$root.headerHeight - 50)
        },
    },
    mounted () {
        this.setAttributes(true)
    }
})
