import Vue from 'vue'

Vue.component('ProgressTracker', {
    data () {
        return {
            activeSectionTracker: ''
        }
    },
    provide() {
        return {
            updateNavigationState: this.updateNavigationState
        }
    },
    methods: {
        updateNavigationState(section) {
            if (section !== this.activeSectionTracker) {
                this.activeSectionTracker = section
            }

        }
    }
})
